<template>
  <div>
    <this-header />
    <b-overlay
      :show="ShowLog"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>

      <validation-observer ref="simpleRules">
        <div class="container-new pd2 pb-5">

          <div class="d-flex align-items-center">
            <router-link
              v-if="UserData.role
                == 'member'"
              :to="{ name: 'tanghuay-home' }"
            >
              <button
                class="bnt-home"
              >
                <i class="fas fa-home" />
              </button>
            </router-link>
            <div class="txt-right">
              <div class="font-weight-bolder">
                <span class="">รายการโพยหวย</span>
              </div>
            </div>
          </div>

          <div
            class="row mt-10 mb-10"
          >
            <div class="col-6">
              <button
                class="btns text-left w-100 "
                :class="[isActive ? 'primarys' : 'btn-outline-primarys']"
                @click="toggle(1)"
              >
                <i class="icon far fa-calendar-alt" /> <span class="label">โพยหวยทั้งหมด</span>
              </button>
            </div>
            <div class="col-6">
              <button
                class="btns text-left w-100 btn-outline-primarys"
                :class="[isActive2 ? 'primarys2' : 'btn-outline-primarys']"
                @click="toggle(2)"
              >
                <i class="icon far fa-clock" /> <span class="label">โพยหวยยกเลิก</span>
              </button>
            </div>
          </div>

          <!-- List lotto now -->
          <div v-if="isActive">
            <router-link
              v-for="item in items"
              :key="item.id"
              :to="{ name: 'tanghuay-list-detail', params: { id: item.list_id } }"
              class="cards card__stake"
            >
              <div class="stake-header">
                <div class="title">
                  <span class="label">#เลขที่ </span>
                  <span class="number">{{ item.list_id }}</span>
                </div> <div class="status">
                  <span class="user-cancel badge badge-lg badge__light-purple">{{ item.status === 'confirm' ? 'รับแทง' : 'ยกเลิก' }}</span>
                </div></div> <div class="stake-body">
                <div class="stake-credit">
                  <h4 class="amount text-primary">
                    {{ item.lotto_type }}
                  </h4>
                  <div class="date">
                    {{ item.CloseDateName }}
                  </div>
                  <div class="time">
                    {{ Formatt(item.created_at) }}
                  </div>
                </div>
                <div class="stake-detail">
                  <div class="d-flex align-items-center">
                    <span class="label">เดิมพัน</span>
                    <h3 class="mb-0 ml-auto text-primary">
                      {{ item.amount }}
                    </h3>
                  </div> <div class="d-flex align-items-center">
                    <span class="label">ผลได้เสีย</span>
                    <h3 class="mb-0 ml-auto text-muted num-zero">
                      0
                    </h3>
                  </div>
                </div>
              </div>
            </router-link>

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="GetLottolist()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>

          <!-- List lotto history -->
          <div v-if="isActive2">
            <router-link
              v-for="item in itemsLog"
              :key="item.id"
              :to="{ name: 'tanghuay-list-detail', params: { id: item.list_id } }"
              class="cards card__stake"
            >
              <div class="stake-header bg-new">
                <div class="title">
                  <span class="label">#เลขที่ </span>
                  <span class="number">{{ item.list_id }}</span>
                </div> <div class="status">
                  <span class="user-cancel badge badge-lg badge__light-purple">{{ item.status === 'confirm' ? 'รับแทง' : 'ยกเลิก' }}</span>
                </div></div> <div class="stake-body">
                <div class="stake-credit">
                  <h4 class="amount text-primary">
                    {{ item.lotto_type }}
                  </h4>
                  <div class="date">
                    {{ item.CloseDateName }}
                  </div>
                  <div class="time">
                    {{ Formatt(item.created_at) }}
                  </div>
                </div>
                <div class="stake-detail">
                  <div class="d-flex align-items-center">
                    <span class="label">เดิมพัน</span>
                    <h3 class="mb-0 ml-auto text-primary">
                      {{ item.amount }}
                    </h3>
                  </div> <div class="d-flex align-items-center">
                    <span class="label">ผลได้เสีย</span>
                    <h3 class="mb-0 ml-auto text-muted num-zero">
                      0
                    </h3>
                  </div>
                </div>
              </div>
            </router-link>

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="GetLottolist()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </validation-observer>
    </b-overlay>
    <this-footer />
  </div>
</template>

<script>
import {
  BOverlay,
  BIconController,
  BPagination,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
// import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
// import moment from 'moment-timezone'
// import {
//   required, integer, between, length,
// } from '@validations'
import ThisHeader from './component/ThisHeader.vue'
import ThisFooter from './component/ThisFooter.vue'

export default {
  components: {
    // vSelect,
    // BInputGroupPrepend,
    ValidationObserver,
    // BProgress,
    BOverlay,
    BIconController,
    ThisHeader,
    ThisFooter,
    BPagination,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      isActive: false,
      isActive2: false,
      Status: 'NOW',
      ShowCancle: false,
      ShowList: false,
      ShowLog: false,
      totalRows: 1,
      currentPage: 1,
      items: [],
      totalRowsLog: 1,
      currentPageLog: 1,
      itemsLog: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      pageOptions: [10, 15, 20],
      perPage: 10,
      fields: [
        { key: 'index', label: 'ลำดับที่' },
        { key: 'list_id', label: 'โพยที่' },
        { key: 'remark', label: 'ชื่อลูกค้า' },
        { key: 'lotto_type', label: 'ประเภทหวย' },
        { key: 'CloseDateName', label: 'งวดประจำวันที่' },
        { key: 'amount', label: 'เงินเดิมพัน' },
        // { key: 'dis_remark', label: 'ส่วนลด' },
        { key: 'status', label: 'สถานะ' },
        { key: 'created_at', label: 'เวลาแทง', formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss') },
        { key: 'actions', label: 'Actions' },
      ],
      fieldsLog: [
        { key: 'index', label: 'ลำดับที่' },
        { key: 'list_id', label: 'โพยที่' },
        { key: 'remark', label: 'ชื่อลูกค้า' },
        { key: 'lotto_type', label: 'ประเภทหวย' },
        { key: 'CloseDateName', label: 'งวดประจำวันที่' },
        { key: 'amount', label: 'เงินเดิมพัน' },
        // { key: 'dis_remark', label: 'ส่วนลด' },
        { key: 'status', label: 'สถานะ' },
        {
          key: 'created_at',
          label: 'เวลาแทง',
          formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'actions', label: 'Actions' },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.GetLottolist()
    this.GetCancle()
    this.toggle(1)
  },
  methods: {
    toggle(val) {
      if (val === 1) {
        this.isActive = true
        this.isActive2 = false
      } else if (val === 2) {
        this.isActive = false
        this.isActive2 = true
      }
    },
    ChangType(Val) {
      this.Status = Val
      this.currentPage = 1
      this.GetData(Val)
      console.log(this.Status)
    },
    Formatt(value) {
      return moment(value).format('DD/MM/YYYY HH:mm:ss')
    },
    GetLottolist() {
      // const index = 0
      this.ShowList = true
      const params = {
        page: this.currentPage,
        perpage: this.perPage,
        UserToken: localStorage.getItem('UserToken'),
      }
      this.$http
        .post(
          'member/bill/Member_list',
          params,
        )
        .then(response => {
          this.onFiltered(response.data)
          this.ShowList = false
        })
        .catch(error => {
          console.log(error)
          this.ShowList = false
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.Mes
      this.totalRows = filteredItems.total
    },
    GetCancle() {
      // const index = 0
      this.ShowCancle = true
      const params = {
        page: this.currentPageLog,
        perpage: this.perPage,
        UserToken: localStorage.getItem('UserToken'),
      }
      this.$http
        .post(
          'member/bill/Member_Cancle',
          params,
        )
        .then(response => {
          this.onFilteredLog(response.data)
          this.ShowCancle = false
        })
        .catch(error => {
          console.log(error)
          this.ShowCancle = false
        })
    },
    onFilteredLog(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.itemsLog = filteredItems.Mes
      this.totalRowsLog = filteredItems.total
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
